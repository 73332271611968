/* body
    padding 100px */
/* .MenuButton {
  background: transparent;
  border: none;
  margin: 30px auto 0;
  display: block;
  font-size: 20px;
  outline: none;

  position: relative; */
/* transition: 0.3s; */

/* padding: 10px; /* the trick*/
/* } 

.MenuButton:before {
  content: ""; */
/* border-bottom: 2px solid white; */
/* padding: 0 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.MenuButton:hover:before,
.MenuButton:focus:before {
  border-bottom: 2px solid white; */
/* transition 0.3s*/
/* transition: 0.3s;
  width: 100%;
  padding: 0; the other part of the trick */
/* }

.MenuButton:active {
  background: black; */
/* color: white;
  transition: none; */
/* } */

.MenuLink,
.FooterLink {
  /* position: relative; */
  text-decoration: none;
  color: black;
}
.FooterLink:hover {
  /* position: relative; */
  text-decoration: underline;
  color: #00838a;
}

/* .MenuButton {
  position: relative;
} */
/* .MenuButton:hover {
  color: #00838a;
  background-color: #f01d1d;
  border-radius: 10px;
} */
.MenuButton:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00838a;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.MenuButton:hover:before,
.MenuButton:focus:before {
  visibility: visible;
  transform: scaleX(1);
}
