#slideMenu {
  width: 100vw;
  height: 100vh;
  background-color: #1279ce;
  position: fixed;
  /* top: 0; */
  left: 0;
  transform: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  /* overflow: scroll; */
  z-index: 1000;
}
  #slideMenu.hide {
  /* transform: translate3d(-100vw, 0, 0); */
  background-color: red;
}
  #slideMenu.show {
  /* transform: translate3d(0vw, 0, 0);
  overflow: hidden; */
  background-color: yellow;
}
/*   .flyoutMenu h2 a {
    color: #333;
    margin-left: 15px;
    text-decoration: none;
}
  .flyoutMenu h2 a:hover {
    text-decoration: underline;
} */
.MenuLink {
  text-decoration: none;
  color: black;
}
.MenuLink:visited {
  color: black;
}
