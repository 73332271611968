body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.link {
  color: #00838a;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Thin.ttf") format("truetype");

  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
